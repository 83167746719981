<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4" style="text-align: justify">
        A series of experiments were conducted with varying reactant concentrations and the rates of
        the reactions were observed. Note that each of these species are hypothetical and do not
        represent actual elements from the periodic table. The results of these experiments as well
        as the balanced reaction are summarized below:
      </p>
      <p class="mb-4 pl-10">
        <chemical-latex content="A + 2B -> 3C" />
      </p>

      <v-simple-table class="mb-6" style="width: 400px">
        <thead>
          <tr>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{Trial}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{[A] (M)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{[B] (M)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{Rate (M/s)}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$1$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="rate1.toFixed(5)" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$2$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="Aconc3.toFixed(3)" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="Bconc2.toFixed(3)" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="rate3.toFixed(5)" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$3$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="Bconc2.toFixed(3)" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="rate2.toFixed(5)" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$4$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="Aconc3.toFixed(3)" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="rate4.toFixed(5)" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-0">a) What is the order with respect to reactant A?</p>

      <v-radio-group v-model="inputs.orderA" class="mb-2 pt-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block pl-6"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="`$\\text{${option.condition}}$`" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">b) What is the order with respect to reactant B?</p>

      <v-radio-group v-model="inputs.orderB" class="mb-2 pt-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block pl-6"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="`$\\text{${option.condition}}$`" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">c) What is the rate constant for the reaction?</p>
      <calculation-input
        v-model="inputs.rateConstantAnswer"
        class="pt-0"
        prepend-text="$k:$"
        append-text="$\text{Units not given}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question171',
  components: {
    StembleLatex,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        orderA: null,
        orderB: null,
        rateConstantAnswer: null,
      },
      options: [
        {condition: 'Zeroth Order', value: '0'},
        {condition: 'First Order', value: '1'},
        {condition: 'Second Order', value: '2'},
        {condition: 'Third Order', value: '3'},
      ],
    };
  },
  computed: {
    Aconc1() {
      return this.taskState.getValueBySymbol('Aconc1').content;
    },
    Bconc1() {
      return this.taskState.getValueBySymbol('Bconc1').content;
    },
    scaleA() {
      return this.taskState.getValueBySymbol('scaleA').content;
    },
    scaleB() {
      return this.taskState.getValueBySymbol('scaleB').content;
    },
    orderA() {
      return this.taskState.getValueBySymbol('orderA').content.value;
    },
    orderB() {
      return this.taskState.getValueBySymbol('orderB').content.value;
    },
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    rate1() {
      return (
        this.rateConstant.toFloat() *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB
      );
    },
    Bconc2() {
      return this.Bconc1.toFloat() * this.scaleB.toFloat();
    },
    rate2() {
      return (
        this.rateConstant.toFloat() *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc2 ** this.orderB
      );
    },
    Aconc3() {
      return this.Aconc1.toFloat() * this.scaleA.toFloat();
    },
    rate3() {
      return this.rateConstant.toFloat() * this.Aconc3 ** this.orderA * this.Bconc2 ** this.orderB;
    },
    rate4() {
      return (
        this.rateConstant.toFloat() *
        this.Aconc3 ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB
      );
    },
  },
};
</script>
